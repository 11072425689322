import classNames from "classnames";
import React from "react";
import Particles from "react-tsparticles";


const Section = ({ children, background = "primary", clip, particles }: Props) => {
  const containerClass = classNames("relative overflow-hidden", {
    'bg-secondary': background === 'primary',
    'bg-primary': background === 'secondary',
  });

  return (
    <div className={containerClass}>
      {particles && <div className="absolute w-full opacity-50">
        <Particles options={particleOptions} />
      </div>}
      <div
        className={`flex items-center justify-center p-4 pt-10 pb-14 bg-${background} ${
          clip ? `clip-${clip}` : ""
        }`}
      >
        <div className="flex flex-col max-w-content">{children}</div>
      </div>
    </div>
  );
};

export default Section;

type Props = {
  background?: "primary" | "secondary" | "tertiary";
  clip?: "primary" | "secondary" | "tertiary";
  particles?: boolean;
  children: React.ReactNode;
};

//https://css-tricks.com/almanac/properties/c/clip-path/

const particleOptions = {
  fpsLimit: 60,
  interactivity: {
    detectsOn: "canvas",
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.1,
        size: 0.1,
      },
      push: {
        quantity: 2,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: "#000000",
    },
    links: {
      color: "#000000",
      distance: 150,
      enable: true,
      opacity: 0.1,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outMode: "bounce",
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      random: true,
      value: 2,
    },
  },
  detectRetina: true,
};