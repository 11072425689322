import styled, { keyframes, css } from "styled-components";
import { flipInY } from "react-animations";
import { Props } from "./types";
import { animationSpeedToSeconds } from "./helpers";
const animationFrames = keyframes`${flipInY}`;

const FlipInY = styled.div`
  ${(props: Props) =>
    css`
      animation: ${animationSpeedToSeconds(props.animationSpeed)}s
    `}
    ${animationFrames}
`;

export default FlipInY;
