import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "./Image";
import classNames from "classnames";
import FlipInY from "./animated/FlipInY";

const Logo = ({ size = "md", animation, animationSpeed }: Props) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(name: { eq: "logo" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: WEBP)
        }
      }
    }
  `);

  const Div =
    animation === "flipInY"
      ? FlipInY
      : ({ children, ...props }) => <div {...props}> {children}</div>;

  const containerClass = classNames("", {
    "w-16": size === "sm",
    "w-28": size === "md",
    "w-36": size === "lg",
    "w-48": size === "xl",
    "w-64": size === "2xl",
  });

  return (
    <Div className={containerClass}>
      <Image image={data.file.childImageSharp} />
    </Div>
  );
};

type Props = {
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  animation?: null | "flipInY";
  animationSpeed?: "slow" | "normal" | "fast";
};

export default Logo;
