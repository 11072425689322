import * as React from "react"
import Layout from "../components/Layout"
import LogoHero from "../components/sections/LogoHero"
import AboutUs from "../components/sections/AboutUs"
import UnderConstruction from "../components/UnderConstruction"
import WhatWeOffer from "../components/sections/WhatWeOffer"

// markup
const IndexPage = () => {
  return (
    process.env.GATSBY_MAINTENANCE_MODE ?
      <UnderConstruction /> :
      <Layout>
        <LogoHero />
        <AboutUs />
        <WhatWeOffer />
      </Layout>
  )
}

export default IndexPage
