import React from "react";
import Text, { TextProps } from "../common/Text";

const Title = (props: Props) => {
  return (
    <div className="pb-4 text-center">
      <Text {...props}>{props.children}</Text>
    </div>
  );
};

export default Title;

type Partial = {
  size: "lg" | "xl" | "5xl";
};

type Props = TextProps & Partial;
