import React from "react";

const Text = ({
  children,
  heading,
  size = "base",
  weight = "normal",
  family = "primary",
}: TextProps) => {
  const containerClass = `text-${size} font-${weight} font-${family}`;
  let Div = <p className={containerClass}>{children}</p>;
  switch (heading) {
    case "h1":
      Div = <h1 className={containerClass}>{children}</h1>;
      break;
    case "h2":
      Div = <h2 className={containerClass}>{children}</h2>;
      break;
    case "h3":
      Div = <h3 className={containerClass}>{children}</h3>;
    default:
      break;
  }

  return Div;
};

export default Text;

export type TextProps = {
  children: React.ReactNode;
  size?: TextSize;
  heading?: "h1" | "h2" | "h3";
  weight?: TextWeight;
  family?: "primary" | "secondary" | "tertiary";
};

export type TextSize = "base" | "sm" | "md" | "lg" | "xl" | "5xl";
export type TextWeight = "bold" | "normal";
