import styled, { keyframes, css } from "styled-components";
import { fadeIn } from "react-animations";
import { Props } from "./types";
import { animationSpeedToSeconds } from "./helpers";

const animation = keyframes`${fadeIn}`;

const FadeIn = styled.div`
  ${(props: Props) =>
    css`
      animation: ${animationSpeedToSeconds(props.animationSpeed)}s ${animation};
    `}
`;


export default FadeIn;
