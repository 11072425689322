import React from "react";
import FadeIn from "./animated/FadeIn";
import BrandName from "./BrandName";
import Logo from "./Logo";
import Section from "./Section";

const UnderConstruction = () => {
  return (
    <Section>
      <FadeIn
        className="flex flex-col items-center w-full h-screen"
        animationSpeed="slow"
      >
        <div className="flex flex-wrap justify-center pt-20">
          <Logo animation="flipInY" animationSpeed="slow" size="2xl" />
          <div className="flex items-end">
            <FadeIn className="flex flex-col">
              <BrandName />
              <h2 className="self-end sm:-mr-4">Let's build it together</h2>
            </FadeIn>
          </div>
        </div>
        <div className="pb-4">Coming soon...</div>
      </FadeIn>
    </Section>
  );
};

export default UnderConstruction;
