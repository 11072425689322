import { AnimationSpeed } from "./types";

export const animationSpeedToSeconds = (speed: AnimationSpeed) => {
  switch (speed) {
    case "slow":
      return 4;
    case "normal":
      return 2;
    case "fast":
      return 1;
    default:
      return 2;
  }
};
