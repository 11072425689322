import React from "react";
import { TextSize } from "./common/Text";
import Title from "./typography/Title";

const BrandName = ({ size = "5xl" }: Props) => {
  return (
    <Title heading="h1" size={size}>
      Software Insiders
    </Title>
  );
};

export default BrandName;

type Props = {
  size?: Exclude<TextSize, "base" | "sm" | "md">;
};
